// Config values
$platform-name: "accesscharityoftheyear"; // Well this repository is actually used for all 2023 charity of the year websites (multiple nations)
$assets-path: "../assets/";


// Colour presets
$_red: #E5173F;
$_teal: #49A2A0;
$_purple: #4b112c;

// Colours
$brand-primary: $_red;
$brand-primary-text-contrast-override: null;
$brand-secondary: $_teal;
$brand-secondary-text-contrast-override: #FFF;

$donate-colour: $brand-primary;
$_accent-colour: $brand-primary;

// Create greyscale
$black: #000;
$grey-dark: #2E2E2E;
$grey: #666666;
$grey-light: #636c72;
$grey-lighter: #eceeef;
$grey-lightest: #f7f7f9;
$white: #FFF;

// Layout
$container-max-width-lg: 1440px;
$container-max-width-md: 1280px;
$container-max-width-sm: 890px;

$container-max-width: $container-max-width-md;
$gap-width: 30px;
$site-bleed: 20px;

$border-radius: 8px;
$border-colour: $grey-lighter;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1280px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey;
$profile-pics-enabled: true;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "logo23.png";
$logo-retina: $logo;
$logo-width: 500px;
$logo-height: 72px;
$logo-absolute: false;
$logo-breakpoint: map-get($breakpoints, lg);
$logo-mobile: $logo;
$logo-mobile-width: 250px;
$logo-mobile-height: 37px;


// Typography
$font-family-base: "Open Sans", sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: normal;
$font-weight-bold: bold;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em; // This will only affect headings within post content
$headings-margin-bottom: 0.5em;
$headings-font-family: inherit;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-decoration-colour: initial;
$link-hover-decoration: underline;
$link-hover-style: darken; // darken or lighten text links on hover

// Icon font
$icon-font-family: "FontAwesome"; // TODO: Add in alternatives here


// Buttons
$btn-background-colour: $brand-secondary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 1.25em;
$btn-padding-y: 0.625em;
$btn-font-family: $font-family-base;
$btn-font-weight: $font-weight-bold;
$btn-font-size: $font-size-base;
$btn-text-transform: none;
$btn-text-decoration: none;
$btn-line-height: 1.25;
$btn-letter-spacing: inherit;
$btn-hover-style: darken; // darken or lighten the button on hover
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-colour);


// Social icons (https://github.com/RaisingITDev/fresco/wiki/social-icons)
// Social network colours - use these by changing $social-icons-use-brand-colours to true
$twitter-brand-colour: #1da1f2;
$facebook-brand-colour: #3b5998;
$youtube-brand-colour: #ff0000;
$instagram-brand-colour: #833ab4;
$linkedin-brand-colour: #0077b5;
$pinterest-brand-colour: #bd081c;
$tripadvisor-brand-colour: #00af87;
$vimeo-brand-colour: #1ab7ea;

$social-icons-background-colour: $btn-background-colour; // A colour, transparent, or 'brand'
$social-icons-colour: text-contrast($social-icons-background-colour); // A colour, or 'brand'
$social-icons-hover-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-width: 40px;
$social-icons-height: 40px;
$social-icons-font-size: 1.2rem;
$social-icons-gap: 5px;
$social-icons-border-radius: $btn-border-radius;
$social-icons-box-shadow: $btn-box-shadow;

// Header specific
$social-icons-header-background-colour: $social-icons-background-colour; // A colour, transparent, or 'brand'
$social-icons-header-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-header-hover-colour: $social-icons-hover-colour; // A colour, or 'brand'

// Footer specific social icons
$social-icons-footer-background-colour: $social-icons-background-colour; // A colour, transparent or 'brand'
$social-icons-footer-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-footer-hover-colour: $social-icons-hover-colour; // A colour, or 'brand'


// Share this page
$share-enabled: true;
$share-text-align: left; // left, center, right
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;


// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;


// Tables
$table-cell-padding: 0.75rem;


// Card
$card-gap-width: $gap-width;
$card-border-radius: 0;
$card-border: 0px;
$card-box-shadow: 0;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $brand-primary;
$card-details-background-gradient: none;
$card-image-padding: 0;
$card-image-border-radius: 0;
$card-details-padding: $spacer * 1.5;
$card-details-background-colour: transparent;
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: $headings-text-transform;
$card-heading-colour: $white;
$card-heading-font-size: $font-size-h5;
$card-heading-margin-bottom: 0;
$card-summary-enabled: true;
$card-summary-colour: $body-colour;
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: transparent;
$card-footer-colour: $body-colour;
$card-footer-padding: 0.25rem $spacer;
$card-min-width: 270px;

// Card basic
$card-basic-image-position: above; // above, below

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom; // top, center, bottom
$card-text-overlay-details-position-x: left; // left, center, right
$card-text-overlay-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card text over
$card-text-over-image-vertical-text-position: center; // top, center or bottom
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 50%;
$card-side-by-side-grow-image: true; // Do you want the image to scale to 100% height of the overall card?
$card-side-by-side-vertical-text-position: top; // top, center or bottom
$card-side-by-side-breakpoint: map-get($breakpoints, sm); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: transparent;
$card-hover-box-shadow: 0;
$card-hover-image-opacity: 1;
$card-hover-image-scale: 1.075;
$card-hover-details-background-colour: transparent;
$card-hover-heading-colour: $white;
$card-hover-summary-colour: $body-colour;

// Event cards
$event-card-floating-date: false;
$event-card-floating-date-background-colour: $card-details-background-colour;
$event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
$event-card-floating-date-border-radius: $border-radius;
$event-card-floating-date-margin-y: $spacer;
$event-card-floating-date-margin-x: $spacer;


// Global alert
$global-alert-background-colour: $brand-primary;
$global-alert-colour: text-contrast($global-alert-background-colour);


// Menu admin
$menu-admin-enabled: false;
// $menu-admin-max-width: 100%; // $container-max-width
// $menu-admin-background-colour: $grey-lighter;
// $menu-admin-background-gradient: none; // none or CSS gradient
// $menu-admin-contents-max-width: $container-max-width; // 100%
// $menu-admin-font-size: $font-size-small;
// $menu-admin-align-items: right; // left, center, right
// $menu-admin-link-colour: $link-colour;
// $menu-admin-absolute: false;
// $menu-admin-login-link-enabled: true;
// $menu-admin-manager-link-enabled: true;
// $menu-admin-my-details-link-enabled: true;
// $menu-admin-logout-link-enabled: true;
// $menu-admin-to-nav-breakpoint: 0px;


// Header
$header-background-colour: $white;
$header-background-gradient: none;
$header-box-shadow: none;

$header-absolute: false;
// $header-absolute-background: none;
// $header-absolute-breakpoint: map-get($breakpoints, sm); // When do you want to abandon the absolute header and return to solid?

// Header content
$header-content-max-width: 100%;
$header-content-margin-top: 0;
$header-content-margin-bottom: 0;
$header-content-padding-top: $spacer;
$header-content-padding-bottom: $spacer;
$header-content-padding-left: $site-bleed;
$header-content-padding-right: $site-bleed;

// Tagline
$tagline-enabled: true;
// $tagline-font-family: $headings-font-family;
// $tagline-font-weight: $font-weight-bold;
// $tagline-font-size: $font-size-h5;
// $tagline-colour: $headings-colour;
$tagline-above-cta-breakpoint: 1px;
$tagline-breakpoint: 1px;

// Main call to action
$cta-btn-background-colour: $btn-background-colour;
$cta-btn-colour: $btn-text-colour;
$cta-btn-gap: 5px;
// $cta-padding-right-at-nav-breakpoint: 6.5em; // The gap we need to leave so the cta buttons clear the burger menu button after nav breakpoint
$cta-btn-breakpoint: map-get($breakpoints, md);
$cta-donate-on-appeal: false; // Do you want to show the main cta donate button when on appeal post/donate form?

// Search
$header-search-enabled: false;
// $header-search-margin-right: 20px;
// $header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
// $header-search-input-max-width: 200px;
// $header-search-input-height: initial; // If you need to specificy a specific height here
// $header-search-input-margin-right: 8px;
// $header-search-input-padding: $input-padding-y $input-padding-x;
// $header-search-input-placeholder-colour: $input-placeholder-colour;
// $header-search-input-border-radius: $border-radius;
// $header-search-input-border-colour: $border-colour;
// $header-search-input-border-width: 1px 1px 1px 1px; // t-r-b-l
// $header-search-input-background-colour: $white;
// $header-search-input-colour: text-contrast($header-search-input-background-colour);
// $header-search-input-focus-background-colour: $white;
// $header-search-input-focus-colour: text-contrast($header-search-input-focus-background-colour);

// Search - button
// $header-search-button-width: 40px;
// $header-search-button-height: 40px;
// $header-search-button-background-colour: $btn-background-colour;
// $header-search-button-icon-font-size: $social-icons-font-size;
// $header-search-button-icon-colour: text-contrast($header-search-button-background-colour);
// $header-search-button-border-radius: $border-radius;

// Search when in nav
// $nav-search-enabled: $header-search-enabled;
// $nav-search-input-margin-right: 1rem;
// $nav-search-input-padding: $input-padding-y $input-padding-x;
// $nav-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $nav-search-input-border-colour: $header-search-input-border-colour;
// $nav-search-input-border-width: 0; // Should already contrast enough with the menu bg
// $nav-search-input-background-colour: $header-search-input-background-colour;
// $nav-search-button-background-colour: $header-search-button-background-colour;
// $nav-search-button-icon-colour: text-contrast($nav-search-button-background-colour);

// Social icons -- Colours set above (search for '$social-icons-header')
$header-social-icons-margin-right: 0px;
$header-social-icons-margin-bottom: 0;
$header-social-icons-above-cta-buttons: false;
$header-social-icons-breakpoint: map-get($breakpoints, md);


// Sticky header
$header-sticky-enabled: false;
// $header-sticky-background-colour: $header-background-colour;
// $header-sticky-box-shadow: 0 0 10px rgba($black, 0.1);
// $header-sticky-logo: $logo;
// $header-sticky-logo-retina: $header-sticky-logo;
// $header-sticky-logo-width: $logo-width * 0.5;
// $header-sticky-logo-height: $logo-height * 0.5;
// $header-sticky-menu-admin-enabled: false;
// $header-sticky-search-enabled: false;
// $header-sticky-social-icons-enabled: false;
// $header-sticky-cta-btns-enabled: true;
// $header-sticky-header-content-margin-top: $header-content-margin-top * 0.75;
// $header-sticky-header-content-margin-bottom: $header-content-margin-bottom * 0.75;
// $header-sticky-nav-margin-bottom: 0;


// Navigation
$nav-type: normal;
$nav-font-family: $font-family-base;
$nav-breakpoint: 1210px;
$nav-background-colour: $white;
$nav-text-transform: none;

// Top level items
$nav-top-level-item-padding: 12.5px 16.5px;
$nav-top-level-item-colour: text-contrast($nav-background-colour);
$nav-top-level-item-font-family: $nav-font-family;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent;
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-chevrons-enabled: false;
$nav-top-level-chevrons-colour: $nav-top-level-item-colour;
$nav-top-level-first-item-home-icon: false;
$nav-top-level-first-item-home-icon-font-size: 20px;

// Submenus
$nav-submenu-width: 220px;
$nav-submenu-background-colour: $white;
$nav-submenu-box-shadow: 0px 4px 4px 0px rgba($black, 0.25);
$nav-submenu-border-radius: 2px;
$nav-submenu-item-padding: 12.5px 16.5px;
$nav-submenu-item-colour: $body-colour;
$nav-submenu-item-font-family: $nav-font-family;
$nav-submenu-item-font-size: $font-size-base;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-colour: $_red;
$nav-submenu-item-hover-background-colour: rgba($black, 0.075);
$nav-submenu-chevrons-enabled: true;
$nav-submenu-chevrons-colour: $_red;

// Burger button
$burger-btn-background-colour: $white;
$burger-btn-text-colour: $body-colour;
$burger-btn-text-transform: $btn-text-transform;
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: 492px;

// Nav normal
$nav-normal-max-width: calc(100% - 0.0001px);
//$nav-normal-margin-top: -5.65rem;
$nav-normal-margin-bottom: 3rem;
$nav-normal-contents-max-width: 100%;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $brand-primary;
$nav-normal-mobile-top-level-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-submenu-background-colour: rgba($black, 0.08);
$nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour);
$nav-normal-mobile-chevrons-enabled: true;
$nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour);

// Nav - Parent nav items clickable
$nav-parent-clickable-enabled: false;
$nav-parent-clickable-chevron-background-colour: rgba(#fff, 0.75);
$nav-parent-clickable-chevron-colour: $nav-normal-mobile-background-colour;
$nav-parent-clickable-chevron-icon-type: "chevron"; // can take "chevron" or "plus"
$nav-parent-clickable-chevron-active-background-colour: #fff;
$nav-parent-clickable-chevron-active-colour: $nav-parent-clickable-chevron-colour;


// Specifically when no banner present and you have gone for $header-absolute: true
// $no-banner-header-background-colour: $header-background-colour;
// $no-banner-logo: $logo;
// $no-banner-logo-retina: $no-banner-logo;
// $no-banner-tagline-colour: $tagline-colour;
// $no-banner-social-icons-header-background-colour: $social-icons-header-background-colour; // A colour, transparent, or 'brand'
// $no-banner-social-icons-header-colour: $social-icons-header-colour; // A colour, or 'brand'
// $no-banner-social-icons-header-hover-colour: $social-icons-header-hover-colour; // A colour, or 'brand'
// $no-banner-nav-background-colour: $nav-background-colour;
// $no-banner-nav-top-level-item-colour: $nav-top-level-item-colour;
// $no-banner-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $no-banner-header-search-input-placeholder-colour: $header-search-input-placeholder-colour;
// $no-banner-header-search-input-border-colour: $header-search-input-border-colour;
// $no-banner-header-search-input-background-colour: $header-search-input-background-colour;
// $no-banner-header-search-button-background-colour: $header-search-button-background-colour;
// $no-banner-header-search-button-icon-colour: $header-search-button-icon-colour;


// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
$carousel-margin-top: 0;
$carousel-margin-bottom: 0;
$carousel-image-overlay: rgba($black, 0.1);
$carousel-contents-max-width: $container-max-width; 
$carousel-breakpoint: 980px;
$carousel-details-style: overlay;
$carousel-details-position-y: bottom;
$carousel-details-position-x: left;
$carousel-details-background-colour: $white;
$carousel-details-box-shadow: none;
$carousel-details-max-width: calc(50% - #{$card-gap-width / 2});
$carousel-details-min-height: auto;
$carousel-details-padding: $spacer * 1.5;
$carousel-details-margin-y: $spacer;
$carousel-details-margin-x: 0;
$carousel-details-border-radius: $border-radius;
$carousel-details-text-align: left;
$carousel-heading-text-transform: $headings-text-transform;
$carousel-heading-colour: text-contrast($carousel-details-background-colour);
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: true;
$carousel-summary-colour: text-contrast($carousel-details-background-colour);
$carousel-summary-font-size: $font-size-base;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: $brand-secondary;
$carousel-read-more-border-colour: $carousel-read-more-background-colour;
$carousel-read-more-colour: text-contrast($carousel-read-more-background-colour);
$carousel-read-more-font-size: $font-size-small;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;
$carousel-controls-button-size: 40px;
$carousel-controls-button-gap: 0px;
$carousel-controls-button-background-colour: $btn-background-colour;
$carousel-controls-button-border-radius: $border-radius;
$carousel-controls-button-box-shadow: $box-shadow;
$carousel-controls-position-y: bottom; // top, center, bottom
$carousel-controls-position-x: right; // split, left or right
$carousel-controls-margin-y: 0px;
$carousel-controls-margin-x: 0px;
$carousel-controls-icon-colour: $btn-text-colour;
$carousel-controls-icon-font-size: 1em;
$carousel-controls-next-icon: "\f054"; // TODO: These are temporarily suspended
$carousel-controls-prev-icon: "\f053"; // TODO: These are temporarily suspended

// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: true;
$carousel-buttons-type: dots; // dots or tabs

// Carousel buttons dots
$carousel-dots-position-y: bottom; // top, bottom
$carousel-dots-position-x: left; // left, center, right
$carousel-dots-padding: $spacer;
$carousel-dots-colour: $white;
$carousel-dots-opacity: 0.5;
$carousel-dots-active-colour: $brand-primary;

// Carousel buttons tabs
$carousel-tabs-font-size: $font-size-h3;
$carousel-tabs-background-colour: $grey-lighter;
$carousel-tabs-colour: $brand-primary;
$carousel-tabs-active-background-colour: $brand-primary;
$carousel-tabs-active-colour: text-contrast($carousel-tabs-active-background-colour);
$carousel-tabs-active-triangle-enabled: true;
$carousel-tabs-text-align: left;
$carousel-tabs-font-weight: $btn-font-weight;
$carousel-tabs-text-transform: $btn-text-transform;
$carousel-tabs-padding-x: $spacer;
$carousel-tabs-padding-y: $spacer;
$carousel-tabs-separator-width: 0px;
$carousel-tabs-separator-colour: $border-colour;

// Carousel video play button
$carousel-video-btn-position-y: 50%;
$carousel-video-btn-position-x: 75%;

// Carousel scroll button
$carousel-scroll-btn-enabled: false;
$carousel-scroll-btn-size: $carousel-controls-button-size;
$carousel-scroll-btn-background-colour: $btn-background-colour;
$carousel-scroll-btn-border-radius: $border-radius;
$carousel-scroll-btn-box-shadow: $box-shadow;
$carousel-scroll-btn-icon-colour: $btn-text-colour;
$carousel-scroll-btn-icon-font-size: 1em;
// $carousel-scroll-btn-icon: '\f078';
$carousel-scroll-btn-margin-y: 15%;
$carousel-scroll-btn-animation-prefab: 0;

// Carousel background YouTube video
$carousel-yt-background-enabled: false;
$carousel-yt-background-size: "cover";
$carousel-yt-cover-padding-bottom: 56.25%;

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $grey-lightest;
$carousel-mobile-heading-colour: text-contrast($carousel-mobile-details-background-colour);
$carousel-mobile-summary-colour: text-contrast($carousel-mobile-details-background-colour);

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 200px;


// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: $grey-lightest;
$home-intro-background-gradient: none; // none or CSS gradient
$home-intro-background-image: null;
$home-intro-background-image-opacity: 0.75;
$home-intro-box-shadow: none;
$home-intro-content-max-width: 100%;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 4;
$home-intro-margin-top: 0;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $headings-font-family; // Will affect all elements within the home intro snippet
$home-intro-font-size: $font-size-base;
$home-intro-font-weight: $headings-font-weight;
$home-intro-line-height: $line-height-base;
$home-intro-colour: text-contrast($home-intro-background-colour);
$home-intro-border-radius: 0;
$home-intro-mobile-padding-y: $home-intro-padding-y;
$home-intro-mobile-font-size: 4em;


// Home headlines intro
$home-headlines-intro-enabled: false;
// $home-headlines-intro-snippet-title-enabled: false;
// $home-headlines-intro-max-width: 100%;
// $home-headlines-intro-content-max-width: $container-max-width;
// $home-headlines-intro-background-colour: $grey-lightest;
// $home-headlines-intro-padding-top: $spacer * 4;
// $home-headlines-intro-padding-bottom: $spacer *  4;
// $home-headlines-intro-margin-top: 0;
// $home-headlines-intro-margin-bottom: 0;
// $home-headlines-intro-text-align: left;
// $home-headlines-intro-text-transform: $headings-text-transform;
// $home-headlines-intro-font-family: $headings-font-family;
// $home-headlines-intro-font-size: $font-size-base;
// $home-headlines-intro-colour: text-contrast($home-headlines-intro-background-colour);


// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: 0;
$home-features-padding-bottom: 0;
$home-features-cards-entry-animation-prefab: 0;
$home-features-cards-entry-animation-stagger: true;
$home-features-cards-entry-animation-stagger-count: 3;


// Impact stats
$impact-stats-enabled: true;
$impact-stats-max-width: 100%;
$impact-stats-margin-top: 0;
$impact-stats-margin-bottom: 0;
$impact-stats-padding-y: $spacer * 4;
$impact-stats-background-colour: $_accent-colour;
// $impact-stats-background-gradient: linear-gradient(90deg, rgba(233,34,38,1) 0%, rgba(20,140,223,1) 100%);
$impact-stats-background-image: 'home-stats-background.svg';
$impact-stats-background-image-opacity: 0.3;
$impact-stats-background-image-size: cover;
$impact-stats-background-image-repeat: no-repeat;
$impact-stats-border-radius: 0;
$impact-stats-colour: $white;
$impact-stats-text-align: left;
$impact-stats-content-max-width: $container-max-width;
$impact-stats-breakpoint: map-get($breakpoints, md); // When do you want to break the overall layout from row to column?

$impact-stats-overall-container-direction: column; // column, row for the .homeImpactWrapper
$impact-stats-direction: row; // Do you want the stats in a row or column?
$impact-stats-mobile-text-align: $impact-stats-text-align;

// Heading
$impact-stats-heading-enabled: true; //true or false
$impact-stats-heading-font-size: $font-size-h2;
$impact-stats-heading-text-align: $impact-stats-text-align; // left, center, right
$impact-stats-heading-colour: $impact-stats-colour;
$impact-stats-heading-margin-bottom: $spacer * 1.5;
$impact-stats-heading-text-transform: $headings-text-transform;

// Individual stat
$impact-stat-min-width: 180px; // Use this to control how many you get in a row
$impact-stat-direction: column; // column, row
$impact-stat-padding: 0px 0px;
$impact-stat-margin: $gap-width;
$impact-stat-border: 0px solid $border-colour; // Style border here
$impact-stat-border-width: 0 0 0 0; // Set border widths (t-r-b-l) here

// Figure
$impact-stats-figure-colour: transparent;
$impact-stats-figure-font-family: $headings-font-family;
$impact-stats-figure-font-size: 5.5em;

// Summary
$impact-stats-summary-colour: inherit ;
$impact-stats-summary-font-size: $font-size-large;
$impact-stats-summary-font-weight: $font-weight-base;
$impact-stats-summary-margin-top: 0;


// Home feeds
$home-feeds-max-width: $container-max-width;
$home-feeds-background-colour: $white;
$home-feeds-padding-top: $spacer * 5;
$home-feeds-padding-bottom: $spacer * 5;
$home-feeds-cards-entry-animation-prefab: 0; // See https://github.com/RaisingITDev/fresco/wiki/entry-animations
$home-feeds-cards-entry-animation-stagger: true;
$home-feeds-cards-entry-animation-stagger-count: 3;

// Feeds title
$feeds-title-font-family: $headings-font-family;
$feeds-title-font-size: $font-size-h2;
$feeds-title-colour: text-contrast($home-feeds-background-colour);
$feeds-title-text-align: left; // left, center, right
$feeds-title-margin-bottom: $spacer;
$feeds-title-text-transform: $headings-text-transform;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: true;

// Twitter feed
$home-feed-twitter-enabled: false;
// $home-feed-twitter-card-gap-width: $card-gap-width;
// $home-feed-twitter-card-border: $card-border;
// $home-feed-twitter-card-border-radius: $card-border-radius;
// $home-feed-twitter-card-box-shadow: $card-box-shadow;
// $home-feed-twitter-card-text-align: $card-text-align;
// $home-feed-twitter-card-padding: $card-details-padding;
// $home-feed-twitter-card-background-colour: $card-details-background-colour;
// $home-feed-twitter-card-colour: text-contrast($home-feed-twitter-card-background-colour);
// $home-feed-twitter-card-min-width: $card-min-width;


// Footer
$footer-boxes-count: 3;
$footer-prefab: 7;
$footer-max-width: 100%;
$footer-contents-max-width: $container-max-width;
$footer-margin-y: 0;
$footer-padding-y: $spacer * 3;
$footer-background-colour: $grey-dark;
$footer-background-gradient: none;
$footer-colour: text-contrast($footer-background-colour);
$footer-link-colour: rgba(text-contrast($footer-background-colour), 0.75);
$footer-link-decoration: none;
$footer-link-hover-colour: text-contrast($footer-background-colour);
$footer-link-hover-decoration: underline;
$footer-text-align: center;
$footer-font-size: inherit;
$footer-headings-font-size: inherit;
$footer-headings-text-transform: $headings-text-transform;

// Footer admin links
$footer-admin-basket-link-enabled: false;
$footer-admin-login-link-enabled: true;
$footer-admin-manager-link-enabled: false;
$footer-admin-my-details-link-enabled: false;
$footer-admin-logout-link-enabled: true;

// Newsletter
$newsletter-background-colour: $footer-background-colour;
$newsletter-background-gradient: none; // none or CSS gradient
$newsletter-colour: text-contrast($newsletter-background-colour);
$newsletter-heading-font-family: $headings-font-family;
$newsletter-heading-font-size: $font-size-h2;
$newsletter-heading-text-align: left;
$newsletter-heading-text-transform: $headings-text-transform;
$newsletter-heading-margin-bottom: $spacer * 2;
$newsletter-heading-colour: $newsletter-colour;
$newsletter-input-background-colour: $white;
$newsletter-button-height: 38px; // Hard code this so you can match it with the inputs
$newsletter-button-background-colour: $brand-secondary;
$newsletter-button-colour: text-contrast($newsletter-button-background-colour);
$newsletter-button-border-colour: $brand-secondary;
$newsletter-use-placeholders: false; // Turn on to use placeholders in the inputs rather than labels
$newsletter-required-label-enabled: false;

// Newsletter hero - full width form above rest of footer
$newsletter-hero-padding-y: $spacer * 3;
$newsletter-hero-contents-max-width: $footer-contents-max-width;


// Context container
$context-container-background-colour: $grey-lightest;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Donors list
$donors-list-enabled: true;
$donors-list-profile-pics-enabled: $profile-pics-enabled;


// Posts
$post-content-max-width: $container-max-width;
$post-body-background-colour: transparent;


// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 3;
$header-text-margin-bottom: $spacer * 1.5;


// Page title
$page-title-font-size: $font-size-h1;
$page-title-font-family: $headings-font-family;
$page-title-font-weight: $headings-font-weight;
$page-title-colour: $headings-colour;
$page-title-text-transform: $headings-text-transform;
$page-title-text-align: left; // left, center or right
$page-title-margin-top: 0;
$page-title-margin-bottom: $headings-margin-bottom;
$page-title-md-font-size: $page-title-font-size * 0.8; // <= $breakpoints.md
$page-title-sm-font-size: $page-title-font-size * 0.6; // <= $breakpoints.sm

// Page title over banner
$page-title-over-banner-enabled: false;
$page-title-over-banner-breakpoint: map-get($breakpoints, md);
$page-title-over-banner-background-colour: $brand-primary; // Add a background colour so that title is always legible as it appears over a photo
$page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour);
$page-title-over-banner-breadcrumb-enabled: true; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird


// Sidebar
$sidebar-enabled: false; // Sidebars will always exist on event and appeal posts
$sidebar-layout: adjacent; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-mobile-layout: below; // above or below - where does the sidebar go on mobile?
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 5; // distance between sidebar and post content
$sidebar-background-colour: transparent;
$sidebar-padding: 0;
$sidebar-border-radius: $context-container-border-radius;

// Associated lists
$associated-latest-enabled: true;
$associated-related-enabled: true;
$associated-most-read-enabled: true;
$associated-tag-cloud-enabled: false;
$associated-max-number-of-posts: 4;
$associated-list-heading-font-size: $font-size-h4;
$associated-item-image-enabled: false;
$associated-item-heading-font-size: $font-size-base;
$associated-item-heading-colour: $link-colour;
$associated-item-headings-text-transform: $headings-text-transform;
$associated-item-summary-enabled: false;
$associated-item-border: true;


// Listing
$listing-content-max-width: $container-max-width;
$listing-intro-max-width: $listing-content-max-width;
$listing-body-background-colour: transparent;
$listing-cards-per-row-desktop: 3;
$listing-cards-per-row-tablet: 2;
$listing-card-min-width: $card-min-width; // Either a px value or 100%. Adjust this value to dictate how many listing items you want in a single row

// Listed post settings
$listed-post-read-more-enabled: false;
$listed-news-post-publish-date-enabled: true;
$listed-blog-post-publish-date-enabled: true;
$listed-blog-post-author-enabled: true;
$listed-event-post-start-date-enabled: true;
$listed-event-post-location-enabled: true;
$listed-appeal-totaliser-enabled: true;


// Breadcrumb
$breadcrumb-enabled: true;
$breadcrumb-disabled-on-listing: false; // Opt out of showing breadcrumbs on listing pages, if breadcrumbs are enabled
$breadcrumb-margin-top: $spacer;
$breadcrumb-margin-bottom: $spacer;
$breadcrumb-max-width: $post-content-max-width;
$breadcrumb-text-align: $page-title-text-align; // left, center or right


// Blockquote
$blockquote-max-width: $post-content-max-width; // Either a px value or 100%
$blockquote-background-colour: $brand-primary;
$blockquote-background-gradient: none; // none or CSS gradient
$blockquote-border-radius: $border-radius;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: $spacer * 2;
$blockquote-text-align: center; // left, center, right
$blockquote-font-size: 1.5em; // Em unit will cascade to children
$blockquote-font-family: $headings-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: $line-height-base;
$blockquote-colour: text-contrast($blockquote-background-colour);
$blockquote-box-shadow: $box-shadow;


// Fundraising
$fundraising-enabled: true; // Quickly turn off fundraising features

// Find a fundraiser widget
$find-a-fundraiser-widget-enabled: true;
$find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled;

// Top fundraisers
$top-fundraisers-enabled: true;
$top-fundraisers-profile-pics-enabled: $profile-pics-enabled;


// Donation form
$donation-form-layout: columns; // rows or columns
$donation-form-banner-enabled: true;
$donation-form-frequency-tabs: false; // Have you added the DonationTarrifsAndTabs.js custom layout?

// Donation amounts
$donation-amount-min-width: $card-min-width; // IF using columns layout, set a min-width value on the amounts to dictate how many columns you want. Default will be 3 (items grow to fill row)
$donation-amount-figure-font-size: $font-size-h2;
$donation-amount-figure-colour: $donate-colour;
$donation-amount-thumbnail-enabled: true;


// Quick giving panel
$quick-giving-type: simple; // simple, columns or rows
$quick-giving-background-colour: $context-container-background-colour;
$quick-giving-background-gradient: none; // none or CSS gradient
$quick-giving-padding: $context-container-padding;
$quick-giving-border-radius: $context-container-border-radius;
$quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size * 0.75;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $donate-colour;
$quick-giving-donation-amount-figure-colour: text-contrast($quick-giving-donation-amount-background-colour);
$quick-giving-donation-amount-border-width: $btn-border-width;
$quick-giving-donation-amount-border-colour: $donate-colour;
$quick-giving-donation-amount-selected-background-colour: $white;
$quick-giving-donation-amount-selected-figure-colour: text-contrast($quick-giving-donation-amount-selected-background-colour);


// Home quick giving panel
$home-quick-giving-enabled: true;
$home-quick-giving-type: simple; // simple, adjacent or overlay
$home-quick-giving-max-width: 100%;
$home-quick-giving-content-max-width: 100%;
$home-quick-giving-margin-top: 0;
$home-quick-giving-margin-bottom: 0;
$home-quick-giving-padding-y: $spacer * 5;
$home-quick-giving-background-colour: $_accent-colour;
$home-quick-giving-background-gradient: $quick-giving-background-gradient; // none or CSS gradient
$home-quick-giving-background-image: 'home-stats-background.svg';
$home-quick-giving-background-image-opacity: 0.5;
$home-quick-giving-donation-amount-background-colour: $white;
$home-quick-giving-donation-amount-figure-colour: text-contrast($home-quick-giving-donation-amount-background-colour);
$home-quick-giving-donation-amount-border-width: $btn-border-width; // Only comes into play with transparent background
$home-quick-giving-donation-amount-border-colour: $donate-colour;
$home-quick-giving-donation-amount-selected-background-colour: $white;
$home-quick-giving-donation-amount-selected-figure-colour: $donate-colour;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: text-contrast($home-quick-giving-donate-btn-background-colour);
$home-quick-giving-donate-btn-border-colour: $donate-colour;
$home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size;
$home-quick-giving-colour: $body-colour; // This should cover any headings and text you have in the snippet
$home-quick-giving-heading-colour: $headings-colour;
$home-quick-giving-heading-font-size: $font-size-h2;

// Adjacent homepage quick giving
$home-quick-giving-type-adjacent-image-side: left; // If type is adjacent, which side do you want the image

// Overlay homepage quick giving
$home-quick-giving-type-overlay-items-side: left; // If type is overlay, which side do you want the copy and donation items?
$home-quick-giving-type-overlay-gradient: linear-gradient(to left, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.75) 100%);


// Button actions
$button-actions-padding: 15px;
$button-actions-background-colour: $context-container-background-colour;
$button-actions-align-buttons: left; // left, center, right


// Totalisers
$totaliser-enabled: true;
$totaliser-layout: horizontal; // vertical or horizontal
$totaliser-width: 60px;
$totaliser-panel-background-colour: transparent;


// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;


// Blog details
$blog-details-enabled: true;
$blog-details-background-colour: $context-container-background-colour;
$blog-details-padding: $context-container-padding;
$blog-details-border-radius: $context-container-border-radius;
$blog-details-profile-pic-enabled: $profile-pics-enabled;


// FAQs
$faq-content-max-width: $post-content-max-width;
$faq-item-header-background-colour: $context-container-background-colour;
$faq-item-header-padding: $context-container-padding;
$faq-item-header-border-radius: $context-container-border-radius;
$faq-item-header-font-size: $font-size-h4;
$faq-item-btn-background-colour: $btn-background-colour;
$faq-item-btn-chevron-colour: $btn-text-colour;
// $faq-item-hover-btn-background-colour: button-hover-state($faq-item-btn-background-colour);
// $faq-item-hover-btn-chevron-colour: text-contrast($faq-item-hover-btn-background-colour);


// In Memory
$listing-inmem-masonry-layout: true;


// Shop
$shop-enabled: false; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)
// $basket-link-only-on-shop-pages: false; // Set to true to only show the basket link (in both header and footer admin menus) on 'shop' related pages only

// // Departments list
// $department-list-width: $sidebar-adjacent-width;
// $department-list-background-colour: $context-container-background-colour;
// $department-list-padding: $context-container-padding;
// $department-list-border-radius: $context-container-border-radius;

// // Product-price
// $product-price-colour: $brand-secondary;
// $product-price-font-size: $font-size-h4;

// // Product post
// $product-post-content-max-width: $post-content-max-width;

// // Product rating
// $product-rating-enabled: true;


// // Subsite
// $subsite-enabled: false;
// $subsite-hide-main-header: false;
// $subsite-hide-main-tagline: false;
// $subsite-hide-header-search: false;
// $subsite-hide-main-cta: false;
// $subsite-content-max-width: $container-max-width;
// $subsite-title-font-size: $page-title-font-size;

// Subsite logo
// $subsite-logo: "logo.svg"; // Set to 'title' if you want to just use the subsite text title. If you use a logo image, it will act as a link back to the subsite's homepage
// $subsite-logo-retina: $subsite-logo;
// $subsite-logo-width: 120px;
// $subsite-logo-height: 120px;
// $subsite-logo-margin: $header-content-margin-top 0 0;

// // Subsite nav
// $subsite-nav-breakpoint: $nav-breakpoint;
// $subsite-nav-max-width: $nav-normal-max-width;
// $subsite-nav-margin-top: $spacer;
// $subsite-nav-margin-bottom: $spacer;
// $subsite-nav-background-colour: $nav-background-colour;
// $subsite-nav-contents-max-width: $container-max-width;
// $subsite-nav-align-items: $nav-normal-align-items; // left, center, right
// $subsite-nav-top-level-item-padding: $nav-top-level-item-padding;
// $subsite-nav-top-level-item-colour: $nav-top-level-item-colour;
// $subsite-nav-top-level-item-font-family: $nav-top-level-item-font-family;
// $subsite-nav-top-level-item-font-size: $nav-top-level-item-font-size;
// $subsite-nav-top-level-item-font-weight: $nav-top-level-item-font-weight;
// $subsite-nav-top-level-item-background-colour: $nav-top-level-item-background-colour;
// $subsite-nav-top-level-item-hover-colour: $nav-top-level-item-hover-colour;
// $subsite-nav-top-level-item-hover-background-colour: $nav-top-level-item-hover-background-colour;

// $subsite-nav-submenu-width: $nav-submenu-width;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour;


// Cookie consent
$cookie-consent-position: bottom; // Bottom or top
$cookie-consent-background-colour: $black; // You can use rgba here for opacity
$cookie-consent-colour: text-contrast($cookie-consent-background-colour);
$cookie-consent-font-family: initial;
$cookie-consent-btn-background-colour: $btn-background-colour;
$cookie-consent-btn-text-colour: $btn-text-colour;


// Keeping In Touch
$keeping-in-touch-enabled: true;
$keeping-in-touch-via-email-enabled: true;
$keeping-in-touch-via-telephone-enabled: true;
$keeping-in-touch-via-sms-enabled: true;
$keeping-in-touch-via-post-enabled: true;


// Slick
$slick-enabled: false;